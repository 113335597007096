import { toast } from 'react-toastify';
import { freeIpfsGateway, paidIpfsGateway } from '../constants';

export function trimCryptoAddress(address: string) {
  return address.substring(0, 6) + '...' + address.substring(address.length - 4, address.length);
}

export function copyToClipboard(text: string, notification: string): void {
  navigator.clipboard.writeText(text);
  toast.success(notification);
}

export const formatIPFSUri = (uri: string): string => {
  const targetGateway = ['https://ipfs.io/ipfs', freeIpfsGateway];
  uri = uri?.replace('ipfs://', freeIpfsGateway + '/');

  // uri = uri.replaceAll('%', '%25');
  // uri = uri.replaceAll('#', '%23');

  /* eslint no-restricted-syntax: "off" */
  for (const gateway of targetGateway) {
    if (uri?.startsWith(gateway)) {
      return uri.replace(gateway, paidIpfsGateway);
    }
  }

  return uri;
};

export const capitalizeFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

export const getParametersFromUrl = (parametersInUrl: string) => {
  const doesUrlHaveParameters = parametersInUrl.includes('?');

  if (parametersInUrl && doesUrlHaveParameters) {
    const parameters: { [key: string]: any } = {};

    parametersInUrl
      .split('?')[1]
      .split('&')
      .forEach((parametersInUrl) => {
        const parameterKeyValuePairs = parametersInUrl.split('=');

        Object.assign(parameters, { [parameterKeyValuePairs[0]]: parameterKeyValuePairs[1] });
      });

    return parameters;
  }

  return {};
};

export function removeTrailingSlash(str: string) {
  return str.replace(/\/+$/, '');
}
