import { Env } from '@bcode-tech/bcode-sdk';
import packageJson from '../../package.json';

export const environments = {
  LOCAL: 'LOCAL',
  DEV: 'DEV',
  PREPROD: 'PREPROD',
  DEMO: 'DEMO',
  PROD: 'PROD',
};

export const APP_VERSION = packageJson.version;
export const currentEnv = process.env.REACT_APP_ENV ?? 'DEV';
export const sdkEnv = (process.env.REACT_APP_SDK_ENV ?? 'AMOY') as Env;
export const sdkApi = process.env.REACT_APP_SDK_API ?? '';
export const rpcProvider = process.env.REACT_APP_RPC_PROVIDER;
export const pablockTokenAddress = process.env.REACT_APP_BCODE_TOKEN_ADDRESS;
export const pablockMetaTransaction = process.env.REACT_APP_BCODE_META_TRANSACTION;
export const pablockNotarization = process.env.REACT_APP_BCODE_NOTARIZATION;
export const pablockNft = process.env.REACT_APP_BCODE_NFT;
export const pablockMultiSignFactory = process.env.REACT_APP_BCODE_MULTISIGN_FACTORY;
export const alchemyApiKey = process.env.REACT_APP_ALCHEMY_API_KEY;
export const firebaseEndpoint = process.env.REACT_APP_FIREBASE_ENDPOINT;
export const backendEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT;
export const reactAppVerifier = process.env.REACT_APP_VERIFIER;
export const freeIpfsGateway = process.env.REACT_APP_FREE_GATEWAY as string;
export const paidIpfsGateway = process.env.REACT_APP_PAID_GATEWAY as string;
export const explorerPolygon = process.env.REACT_APP_EXPLORER as string;

export const placeholderImage = '/images/placeholder_image.png';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  databaseURL: process.env.REACT_APP_RTDB_MESSAGES_FIREBASE,
};

export const roles = {
  guest: 'guest',
  consumer: 'consumer',
  unverified: 'unverified',
};

export const SECURITY_QUESTIONS: {
  value: string;
  label: string;
}[] = [
  {
    value: 'fav-breed-dog',
    label: 'security_questions.fav_breed_dog',
  },
  {
    value: 'fav-movie',
    label: 'security_questions.fav_movie',
  },
  {
    value: 'first-concert',
    label: 'security_questions.first_concert',
  },
  {
    value: 'city-parent-meet',
    label: 'security_questions.city_parent_meet',
  },
  {
    value: 'first-car',
    label: 'security_questions.first_car',
  },
  {
    value: 'first-pet-name',
    label: 'security_questions.first_pet_name',
  },
];

export const COLORS = {
  primary100: '#f1eafe',
  primary400: '#9368fe',
  primary500: '#642eff',
  secondary100: '#F5F9FF',
  secondary500: '#1c63fe',
  secondary800: '#101052',
  grey50: '#fff0',
  grey100: '#F7F7F7',
  grey200: '#E0E0E0',
  grey300: '#CFD0D5',
  grey400: '#828282',
  grey500: '#4F4F4F',
  grey600: '#333333',
  success: '#118900',
  warning: '#FFBF00',
  error: '#D64045',
  white: '#FFFFFF',
  black: '#0E0E10',
};

export const THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
};

export const STORES = {
  googlePlay: 'https://play.google.com/store/apps/details?id=com.bcode.walletplace',
  appStore: 'https://apps.apple.com/us/app/bcode-walletplace/id6451476111',
};

export const randomPollColors = [
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#FFFF99',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
];
export const NFT_ATTRIBUTES = [
  'text',
  'document',
  'image',
  'warranty_certificate',
  'partner',
  'authenticity_certificate',
  'notarizations',
];

export const NFT_FUNCTIONALITIES = ['restricted_area', 'poll', 'shop', 'product', 'news'];

export const PUBLIC_ROUTES = [
  '/login',
  '/register',
  '/redeem',
  '/nfts',
  '/forgot-password',
  '/share',
];

export const MOBILE_BROWSER_ENABLE_ROUTES = ['/share'];

export const NFT_ACTIVITIES = ['redeem', 'transfer', 'verify', 'update'] as const;

export const SSO_PROVIDERS = ['google', 'apple'];

export const GAME = {
  bgColor: '#7130C5',
  appStore: 'https://apps.apple.com/us/app/bcode-walletplace/id6451476111',
};


export const PUSH_NOTIFICATION_TYPES = {
  NEW_MESSAGE: 'newMessage',
  MODIFIED_NFT: 'modifiedNft',}

export const BASE64_TYPE: { [key: string]: string } = {
  iVB: 'data:image/png;base64,',
  '/9j': 'data:image/jpg;base64,',
  R0I: 'data:image/gif;base64,',
  JVB: 'data:application/pdf;base64,',
  '0M8': 'data:application/msword;base64,',
};
