import { App } from '@capacitor/app';
import axios from 'axios';

const getAppDataFromAppStore = async () => {
  const bundleId = 'com.bcode.walletplace';
  const url = `https://itunes.apple.com/lookup?bundleId=${bundleId}&country=IT`;

  try {
    const { data } = await axios.get(url, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET',
        'Access-Control-Allow-Headers': 'Content-Type, X-Requested-With',
      },
    });

    return data;
  } catch (e) {
    console.log('[ERROR] getAppDataFromAppStore: ', e);
  }
};

/**
 * Retrieves the version of the installed app using the Capacitor plugin `App.getInfo()`.
 * @returns The version of the installed app as a string.
 */
const getInstalledVersion = async (): Promise<string | undefined> => {
  try {
    const app = await App.getInfo();
    if (!app) throw new Error('App.getInfo() returned undefined');

    return app.version;
  } catch (error) {
    console.log(error);
  }
};

export default getInstalledVersion;

/**
 * Checks if the installed version of an app is older than the latest version available on the App Store.
 * @returns {Promise<boolean>} - `true` if the installed version is older than the latest version available on the App Store, `false` otherwise.
 */
export const checkIfVersionIsOld = async (): Promise<boolean> => {
  try {
    const appleData = await getAppDataFromAppStore();
    const installedVersion = await getInstalledVersion();

    return installedVersion !== appleData.results[0].version;
  } catch (error) {
    console.error('Error occurred while checking if version is old:', error);
    return false;
  }
};
