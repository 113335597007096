import axios from 'axios';
import { backendEndpoint } from '../constants';

const result = ({ value, error }: { value?: any; error?: any }) => ({ value, error });

export const api = axios.create({
  baseURL: backendEndpoint,
});

export default api;

/**
 * @description recover file from storage
 * @param fileUrl
 * @param authTokenId
 * @returns
 */
export const getFileFromStorage = async (fileUrl: string, authTokenId: string) => {
  try {
    const response = await api.get(`content/${fileUrl}`, {
      headers: {
        authorization: authTokenId,
      },
    });

    return result({ value: response.data });
  } catch (error) {
    return result({ error });
  }
};

export const getExplorerTx = (transactionHash: string) => {
  return `${process.env.REACT_APP_EXPLORER}/tx/${transactionHash}`;
};