import { SVGProps } from 'react';

const IconChevron = (
  props: SVGProps<SVGSVGElement> & { direction?: 'up' | 'down' | 'left' | 'right' }
) => (
  <svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="transition-[transform] duration-200"
    style={{
      transform: `rotate(${
        props.direction === 'up' || !props.direction
          ? '0deg'
          : props.direction === 'down'
          ? '180deg'
          : props.direction === 'right'
          ? '90deg'
          : '-90deg'
      })`,
    }}
  >
    <g clipPath="url(#clip0_1371_8297)">
      <path
        d="M4.5 15.5L12 8L19.5 15.5"
        stroke={props.stroke ?? 'white'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1371_8297">
        <rect width={24} height={24} fill={props.fill ?? 'white'} transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconChevron;
