import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { remoteConfig } from './firebase';

remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

export const getRemoteConfigValue = (
  key: string,
  type: 'boolean' | 'string' | 'number' | 'json' | 'array'
): any => {
  try {
    const value = getValue(remoteConfig, key);
    return type === 'boolean'
      ? value.asBoolean()
      : type === 'string'
      ? value.asString()
      : type === 'json' || type === 'array'
      ? JSON.parse(value.asString())
      : value.asNumber();
  } catch (e) {
    return type === 'boolean'
      ? false
      : type === 'string'
      ? ''
      : type === 'json'
      ? {}
      : type === 'array'
      ? []
      : 0;
  }
};

export const enableRemoteConfig = () => {
  fetchAndActivate(remoteConfig)
    .then(() => {})
    .catch((err) => {
      console.error(err);
    });
};

export const getRemoteLegals = () => {
  return getRemoteConfigValue('legals', 'json');
};

export const getRemoteWelcomeToken = () => {
  return getRemoteConfigValue('welcomeToken', 'string');
};

export const getRemoteFaq = () => {
  return getRemoteConfigValue('FAQ', 'json');
};

export const getRemoteSuggestedCompanies = () => {
  return getRemoteConfigValue('showSuggestedCompanies', 'boolean');
};