import { createSlice } from '@reduxjs/toolkit';
import { PublicCompany } from '../../contexts/FirestoreContext';

export type companiesState = {
  list: PublicCompany[];
  explorerCompaniesList: PublicCompany[];
  companiesIds: string[];
  followed: PublicCompany[];
  followedAddress: string[];
};

const initialState: companiesState = {
  list: [],
  explorerCompaniesList: [],
  companiesIds: [],
  followed: [],
  followedAddress: [],
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    loadCompany: (state, action) => {
      state = {
        ...state,
        list: [...state.list, action.payload],
        companiesIds: [...state.companiesIds, action.payload.id],
      };

      return state;
    },
    loadCompanies: (state, action) => {
      state = {
        ...state,
        list: [...state.list, ...action.payload],
        companiesIds: [
          ...state.companiesIds,
          ...action.payload.map(({ _id }: { _id: string }) => _id),
        ],
      };

      return state;
    },
    loadExplorerCompany: (state, action) => {
       state = {
        ...state,
        explorerCompaniesList: [...state.explorerCompaniesList, action.payload],
      };

      return state;
    },
    loadFollowed: (state, action) => {
      const index = state.followed.findIndex(({ address }) => action.payload.address === address);

      if (index === -1) {
        state = {
          ...state,
          followed: [...state.followed, action.payload],
          followedAddress: [...state.followedAddress, action.payload.address],
        };
      } else {
        state = {
          ...state,
          followed: state.followed.filter(({ address }) => address !== action.payload.address),
          followedAddress: state.followedAddress.filter(
            (address) => address !== action.payload.address
          ),
        };
      }

      return state;
    },
    logOutCompanies: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { 
  loadCompany, 
  loadCompanies, 
  logOutCompanies, 
  loadFollowed, 
  loadExplorerCompany,
 } = companiesSlice.actions;
export default companiesSlice.reducer;
