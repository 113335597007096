import { Messages } from "../../api/messages";

export const getUnreadMessages = (
    messages: Messages[], 
    companyOptions: {
        [key: string]: {[key: string]: boolean;};
    },
) : number => {
    return messages
    .map((messages) =>
      messages.messages
        .map(
          (message) =>
            message.read === false && !companyOptions?.[messages?.company?.address]?.muted
        )
        .filter(Boolean)
    )
    .flat().length;
};