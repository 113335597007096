import { ReactNode } from 'react';
/* import './button.css'; */

type ButtonProps = {
  action?: () => void;
  children?: ReactNode;
  className?: string;
  /*icon?: JSX.Element;
  iconSize?: 'sm' | 'md' | 'lg';
  iconColor?: Colors; */
  type?: 'primary' | 'secondary' /*  | 'ghost' */;
  loading?: boolean;
  disabled?: boolean;
  cy?: string;
};

const Button = ({
  action,
  children,
  className = '',
  type = 'primary',
  loading,
  disabled,
  cy,
}: ButtonProps) => {
  const isDisabled = disabled || loading;

  return (
    <button
      data-cy={cy}
      type="button"
      disabled={isDisabled}
      className={`mx-auto flex h-[50px] w-full max-w-[90vw] items-center justify-center rounded-[10px] border-[1px] border-primary-500 font-sans text-body-regular-18 ${
        type === 'primary'
          ? 'bg-primary-500 text-white hover:bg-primary-400 '
          : 'bg-white text-primary-500 hover:bg-primary-100'
      } ${
        isDisabled
          ? type === 'primary'
            ? '!border-grey-600 !bg-grey-600 !text-white hover:bg-grey-600'
            : '!bg-white !text-grey-500 hover:bg-white'
          : type === 'primary'
          ? 'bg-primary-500 text-white hover:bg-primary-400 '
          : 'bg-white text-primary-500 hover:bg-primary-100'
      }  ${className}`}
      onClick={action}
    >
      {children}
    </button>
  );
};

export default Button;
