import { SVGProps } from 'react';

const IconModify = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.00001 20.7496H4.5C4.30109 20.7496 4.11033 20.6706 3.96967 20.53C3.82902 20.3893 3.75 20.1985 3.75 19.9996V15.809C3.74966 15.7116 3.76853 15.6151 3.80553 15.525C3.84253 15.4349 3.89694 15.353 3.96563 15.284L15.2156 4.034C15.2854 3.96314 15.3686 3.90686 15.4603 3.86845C15.5521 3.83004 15.6505 3.81026 15.75 3.81026C15.8495 3.81026 15.9479 3.83004 16.0397 3.86845C16.1314 3.90686 16.2146 3.96314 16.2844 4.034L20.4656 8.21525C20.5365 8.28504 20.5928 8.36822 20.6312 8.45996C20.6696 8.55171 20.6894 8.65017 20.6894 8.74963C20.6894 8.84909 20.6696 8.94755 20.6312 9.03929C20.5928 9.13103 20.5365 9.21422 20.4656 9.284L9.00001 20.7496Z"
      stroke={props.color || "white"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M20.25 20.7496H9" stroke={props.color || "white"} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12.75 6.49959L18 11.7496"
      stroke={props.color || "white"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconModify;
