import { SVGProps } from 'react';

const IconCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width ?? 24}
    height={props.height ?? 25}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1004_7468)">
      <path
        d="M15.75 16.25H20.25V4.25H8.25V8.75"
        stroke={props.color || "white"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 8.75H3.75V20.75H15.75V8.75Z"
        stroke={props.color || "white"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1004_7468">
        <rect width={24} height={24} fill={props.color || "white"} transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconCopy;
